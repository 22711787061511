.message-container-preview {
  background-image: url(../../assets/images/bg-ws-light.jpg); /* Ruta de tu imagen de fondo */
  background-size: contain; /* Ajusta la imagen para cubrir todo el contenedor */
  flex-grow: 1;
  padding: 10px;
  max-height: 500px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  white-space: pre-wrap; /* Reconocer saltos de línea */
}

.message-container-preview::-webkit-scrollbar {
  width: 8px; /* Ancho de la barra de desplazamiento */
}

.message-container-preview::-webkit-scrollbar-thumb {
  background-color: rgba(99, 99, 99, 0.2); /* Color de la barra de desplazamiento */
  border-radius: 10px; /* Borde redondeado de la barra de desplazamiento */
}

.message-container-preview::-webkit-scrollbar-thumb:hover {
  background-color: rgba(85, 85, 85, 0.4); /* Cambia el color de la barra de desplazamiento al hacer hover */
}

.message-container-preview::-webkit-scrollbar-track {
  background: transparent; /* Fondo transparente */
}

.received-message-preview {
  background-color: #FFFFFF; /* Color de fondo de mensajes recibidos */
  font-size: 13px;
  padding: 5px 10px 10px 5px; /* Ajustar el padding para acomodar mejor el contenido */
  margin: 5px 10px 0; /* Ajustar el margen */
  border-radius: 10px;
  color: #000000;
  display: block;
  flex-direction: column;
  position: relative;
  width: fit-content; /* Ajusta automáticamente el ancho al contenido */
  max-width: 70%; /* Ancho máximo para evitar que el texto sea demasiado ancho */
  min-width: 20%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); /* Agregar sombra */
}

.received-message-preview::after {
  content: '';
  position: absolute;
  top: 0%;
  left: -3px; /* Ajusta la posición del triángulo */
  transform: translateY(-50%) rotate(-40deg); /* Rotación del triángulo */
  border-width: 12px;
  border-style: solid;
  border-color: transparent transparent transparent #FFFFFF; /* Color del triángulo */
}

.image-preview {
  width: 100%; /* Asegura que ocupe todo el ancho del contenedor */
  height: auto;
  object-fit: cover ; /* Cubre el espacio sin deformar la imagen */
  border-radius: 10px; /* Mantiene las esquinas redondeadas */
}

/* Vista previa de archivo */
.file-preview {
  display: flex;
  align-items: center;
  background-color: #f8f9fa;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
}

.file-icon img {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.file-details span {
  display: block;
  font-size: 14px;
}