/* Tipografía y fondo */
body {
  font-family: 'Nunito', sans-serif;
  background-color: #f9f9f9; /* Fondo blanco suave */
  color: #333; /* Texto gris oscuro */
  margin: 0;
  padding: 0;
}

/* Contenido de la página */
.page-content-task {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: calc(90vh - 80px); /* Altura ajustada */
  margin-top: 80px;
  overflow-x: auto; /* Habilita el scroll horizontal */
  overflow-y: auto; /* Desactiva el scroll vertical */
  padding: 20px;
  gap: 20px; /* Espaciado entre las columnas */
  box-sizing: border-box;
}

.page-content-task::-webkit-scrollbar {
  height: 6px; /* Estilo del scroll horizontal */
}

.page-content-task::-webkit-scrollbar-thumb {
  background-color: #bdbdbd;
  border-radius: 8px;
}

.page-content-task::-webkit-scrollbar-track {
  background-color: #f0f0f0;
}

/* Columnas de tareas */
.task-column {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 20px;
  margin-right: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  min-width: 400px; /* Asegúrate de que el ancho mínimo sea adecuado */
  max-width: 400px; /* Puedes eliminar esto si no quieres limitar el ancho */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  min-height: 80%; /* Altura mínima de la columna */
  flex-grow: 1; /* Permite que las columnas crezcan si el contenido lo requiere */
  overflow-y: auto; /* Activar el scroll vertical cuando el contenido sea mayor que el alto mínimo */
  box-sizing: border-box;
}

.task-column::-webkit-scrollbar {
  width: 6px; /* Ancho del scroll (reduce el valor según lo desees) */
}

.task-column::-webkit-scrollbar-thumb {
  background-color: #bdbdbd;
  border-radius: 8px;
}

.task-column::-webkit-scrollbar-track {
  background-color: #f0f0f0;
}
/* Botón "Agregar tarea" estilo plano */
.add-task-box {
  background-color: #f3f8fd; /* Fondo azul claro */
  color: #007bff; /* Texto azul */
  border: 1px dashed #007bff; /* Borde azul punteado */
  border-radius: 6px;
  margin-bottom: 10px;
  padding: 10px 15px;
  font-size: 14px;
  display: flex;
  align-items: center; /* Centrar el ícono con el texto */
  gap: 8px; /* Espaciado entre el ícono y el texto */
  cursor: pointer;
  width: 100%; /* Alinearlo con el ancho de la tarjeta */
  text-align: center;
  transition: background-color 0.3s ease, border 0.3s ease;
}

.add-task-box:hover {
  background-color: #d6ebff; /* Fondo ligeramente más oscuro */
  border-color: #0056b3; /* Borde más intenso */
  color: #0056b3; /* Texto más oscuro */
}

.add-task-box i {
  font-size: 16px; /* Tamaño del ícono */
  color: inherit; /* Hereda el color del texto */
}

.task-column .add-task-box p {
  margin: 0;
  font-size: 14px;
}


.task-column .delete-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 16px;
  color: #d32f2f;
  cursor: pointer;
}

.task-column .delete-btn:hover {
  color: #b71c1c;
}
/* Tarjeta de tarea */
.task-card {
  background-color: #fafafa; 
  padding: 15px;
  border-radius: 6px;
  margin-bottom: 15px;
  width: 100%;
  display: flex;
  flex-direction: column; /* Alineación vertical del contenido */
  gap: 10px;
  border: 1px solid #e0dfdf; /* Borde oscuro */
  box-shadow: 0 4px 8px rgba(231, 231, 231, 0.3); /* Sombra para darle profundidad */
  color: #858585; /* Texto blanco */
  font-size: 14px;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.task-card:hover {
  transform: translateY(-5px); /* Efecto de levantar */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5); /* Sombra más intensa */
}

/* Título de la tarea */
.task-card p {
  margin: 0;
  font-weight: 600; /* Negrita para el título */
  font-size: 16px;
  color: #707070; 
}

/* Sección de detalles: fecha, etiquetas, etc. */
.task-card .details {
  display: flex;
  justify-content: space-between; /* Espaciado entre elementos */
  align-items: center;
  font-size: 13px;
  color: #3d3d3d;
}

.task-card .details span {
  display: flex;
  align-items: center;
  gap: 5px; /* Espaciado entre ícono y texto */
}

/* Íconos */
.task-card .details i {
  font-size: 14px;
  color: #464646;
}

/* Botón eliminar dentro de la tarjeta */
.task-card .delete-btn {
  align-self: flex-end;
  font-size: 18px;
  color: #d32f2f;
  cursor: pointer;
  transition: color 0.3s ease;
}

.task-card .delete-btn:hover {
  color: #b71c1c;
}
/* Contenedor para usuarios y agregar botón */
.user-section {
  margin-top: 20px; /* Separación del contenido superior */
  padding-top: 10px; /* Espaciado interno para el borde */
  border-top: 1px solid #e0e0e0; /* Línea divisoria sutil */
  display: flex;
  align-items: center;
  justify-content: space-between; /* Íconos a la derecha */
  gap: 10px;
}

/* Círculo para fotos de usuarios */
.user-avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #f0f0f0; /* Fondo gris claro para "placeholder" */
  border: 1px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #888;
  overflow: hidden; /* Asegura que la imagen no se salga */
  cursor: pointer;
}

.user-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Imagen ajustada */
}
.user-avatars {
  display: flex;
  align-items: center;
  gap: 8px; /* Espaciado entre los avatares */
  overflow: hidden; /* Evitar que se desborde si es necesario */
}

/* Estilo del marcador "+X" */
.extra-users {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #e0e0e0; /* Fondo gris claro */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #555;
  font-weight: bold;
  border: 1px solid #ddd;
  cursor: default;
}
/* Botón para agregar usuarios */
.add-user-btn {
  background-color: transparent;
  color: #8c00ff; /* Azul para ícono */
  border: none;
  font-size: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  transition: color 0.3s ease, transform 0.3s ease;
}

.add-user-btn:hover {
  color: #0056b3; /* Azul más oscuro al pasar el cursor */
  transform: scale(1.2); /* Efecto de agrandamiento */
}

/* Botón "Agregar flujo nuevo" */
.add-column-btn {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 8px;
  padding: 15px 20px;
  font-size: 14px;
  cursor: pointer;
  align-self: flex-start;
  margin: 10px;
  transition: background-color 0.3s ease;
}

.add-column-btn:hover {
  background-color: #0056b3;
}

/* Formulario de tareas */
.task-form {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.task-form input {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.task-form button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.task-form button:hover {
  background-color: #0056b3;
}

/* Columna para agregar un nuevo flujo con header */
.new-flow-column {
  background-color: #f0f4f7; /* Fondo más claro */
  border: 1px dashed #b0b0b0; /* Borde punteado */
  border-radius: 8px;
  padding: 20px;
  min-width: 300px;
  max-width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.new-flow-column:hover {
  background-color: #e4ebf1; /* Fondo más oscuro al pasar */
}

.new-flow-column h5 {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  color: #007bff;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: color 0.3s ease;
}

.new-flow-column h5 i {
  margin-right: 8px; /* Espaciado entre ícono y texto */
  font-size: 20px;
}

.new-flow-column h5:hover {
  color: #0056b3;
}


/* TaskFlow.css */

.custom-modal-step {
  max-width: 800px;
  background-color: #f4f4f4;
  border-radius: 8px;
  padding: 20px;
}

.task-info {
  border-bottom: 1px solid #ddd;
  margin-bottom: 15px;
  padding-bottom: 10px;
}

.task-title {
  font-size: 1.25rem;
  font-weight: bold;
}

.task-meta {
  font-size: 0.9rem;
  color: #6c757d;
}

.form-section {
  display: flex;
  gap: 15px;
}

.date-section {
  display: flex;
  gap: 15px;
}

.tags-container {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.tag-item {
  background-color: #e0f7fa;
  color: #00796b;
  padding: 5px 10px;
  border-radius: 12px;
  font-size: 0.85rem;
}

.assigned-list {
  margin-top: 10px;
}

.icon-button {
  margin-left: 10px;
}

.completed-step {
  text-decoration: line-through;
  color: #6c757d;
}

.list-group-item {
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.list-group-item:hover {
  background-color: #f8f9fa;
}
