.card {
    position:relative;
    width: 150px;
    height: 150px;
    background: linear-gradient(
      to bottom,
      rgba(151, 161, 255, 0.37) 12%, rgba(0, 26, 255, 0.267) 32%,
      #fffefe 28% 49%,
      #ffffff 51% 74%,
      #ffffff 76%
    );
    border: none;
    border-radius: 35px;
    box-shadow: 0 0 10px rgba(0, 0, 0, .3);
    overflow: hidden;
  }
  

  