/* Estilos del avatar */
.avatar {
  padding: 10px;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  object-fit: contain;
}

.card {
  width: 80%;
  height: auto;
  border-radius: 14px;
  overflow: hidden;
  align-items: flex-start;
  justify-content: center;
  box-shadow: 20px 20px 60px #eceaea, -20px -20px 60px #e6e6e6;
}

/* Nueva clase para el contenedor del texto */
.text-container {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  text-align: left; /* Agrega esta línea para alinear el contenido a la izquierda */
}

.button-plus {
  position: absolute; /* Añade esta línea para posicionar el ícono correctamente */
  right: 20px; /* Ajusta la posición del ícono desde la derecha según tu preferencia */
  top: 20px; /* Ajusta la posición del ícono desde la parte superior según tu preferencia */
  display: flex;
}