//Casos
.ticket {
  width: 950px;;
  background: #ffffff;
  overflow: hidden;
  cursor: pointer;
}

.ticket-header {
  background-color: #0576b9;
  color: #fff;
  height: 150px;
  border: 2px solid #134461;
  border-bottom: 2px dotted #fcfcfcce;
  border-radius: 10px 10px 0 0;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center; /* Alinea el texto al centro horizontalmente */
  padding: 1px;
}

.ticket-header h4 {
  margin: 0; /* Elimina el margen predeterminado del encabezado h4 */

}


.ticket-body {
  background-color: #ffffff;
  max-height: 1000px; /* Establece la altura máxima */
  border: 2px solid #134461;
  border-top: none;
  border-radius: 0 0 10px 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 30px;

}

.dropdown-container {
  position: absolute;
  left: 10px; /* Ajusta este valor según sea necesario */
  top: 0;
  padding: 5px;
  z-index: 1000;
}

.timeLine {
  max-height: 600px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;

}
 /* Estilos para la barra de desplazamiento en navegadores WebKit (Safari y Chrome) */
 .timeLine::-webkit-scrollbar {
  width: 8px; /* Ancho de la barra de desplazamiento */
}

.timeLine::-webkit-scrollbar-thumb {
  background-color: rgba(131, 131, 131, 0.308); /* Color de la barra de desplazamiento */
  border-radius: 10px; /* Borde redondeado de la barra de desplazamiento */
}

.timeLine::-webkit-scrollbar-thumb:hover {
  background-color: rgba(85, 85, 85, 0.4); /* Cambia el color de la barra de desplazamiento al hacer hover */
}

.timeLine::-webkit-scrollbar-track {
  background: transparent; /* Fondo transparente */
}