
.chat-container {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  height: 80vh;
  width: 50vh;
  justify-content: space-between;
}
.management-container {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  height: 80vh;
  overflow-y: auto;
}
/* Estilos para la barra de desplazamiento en navegadores WebKit (Safari y Chrome) */
.management-container::-webkit-scrollbar {
  width: 8px; /* Ancho de la barra de desplazamiento */
}

.management-container::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.2); /* Color de la barra de desplazamiento */
  border-radius: 10px; /* Borde redondeado de la barra de desplazamiento */
}

.management-container::-webkit-scrollbar-thumb:hover {
  background-color: rgba(85, 85, 85, 0.4); /* Cambia el color de la barra de desplazamiento al hacer hover */
}

.management-container::-webkit-scrollbar-track {
  background: transparent; /* Fondo transparente */
}
/* Estilos para el encabezado del chat */
.chat-header {
  background-color: #1B2831;
  color: white;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chat-header-light {
  background-color: #EBE5DE;
  color: rgb(46, 46, 46);
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.chat-header-telegram {
  background-color: #2078b6;
  color: white;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
/* Estilos para el nombre del contacto */
.contact-name {
  font-size: 20px;
  font-weight: 600;
  margin: 0;
  margin-left: 5px ;
  cursor: pointer;
  transition: transform 0.1s ease;
}

.contact-name:hover {
  transform: scale(0.9);
}

/* Estilos para el estado del contacto */
.contact-status {
  font-size: 14px;
  margin: 0;
  margin-left: 10px ;
}

/* Estilos para el contenedor de mensajes */
.message-container {
  background-image: url(../../assets/images/background-whatsapp.png); /* Ruta de tu imagen de fondo */
  background-size:auto; /* Ajusta la imagen para cubrir todo el contenedor */
  flex-grow: 1;
  padding: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
}
.message-container-light {
  background-image: url(../../assets/images/bg-ws-light.jpg); /* Ruta de tu imagen de fondo */
  background-size:contain; /* Ajusta la imagen para cubrir todo el contenedor */
  flex-grow: 1;
  padding: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
}
.message-container-telegram {
  background-image: url('../../assets/images/background-telegram.PNG'); /* Ruta de tu imagen de fondo */
  background-size: cover; /* Ajusta la imagen para cubrir todo el contenedor */
  background-position: center; /* Centra la imagen de fondo */
  background-repeat: no-repeat; /* Evita que la imagen de fondo se repita */
  flex-grow: 1;
  padding: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
}

/* Estilos para la barra de desplazamiento en navegadores WebKit (Safari y Chrome) */
.message-container::-webkit-scrollbar {
  width: 8px; /* Ancho de la barra de desplazamiento */
}

.message-container::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.2); /* Color de la barra de desplazamiento */
  border-radius: 10px; /* Borde redondeado de la barra de desplazamiento */
}

.message-container::-webkit-scrollbar-thumb:hover {
  background-color: rgba(85, 85, 85, 0.4); /* Cambia el color de la barra de desplazamiento al hacer hover */
}

.message-container::-webkit-scrollbar-track {
  background: transparent; /* Fondo transparente */
}
/* Estilos para la barra de desplazamiento en navegadores WebKit (Safari y Chrome) */
.message-container-light::-webkit-scrollbar {
  width: 8px; /* Ancho de la barra de desplazamiento */
}
.message-container-light::-webkit-scrollbar-thumb {
  background-color: rgba(99, 99, 99, 0.2); /* Color de la barra de desplazamiento */
  border-radius: 10px; /* Borde redondeado de la barra de desplazamiento */
}
.message-container-light::-webkit-scrollbar-thumb:hover {
  background-color: rgba(85, 85, 85, 0.4); /* Cambia el color de la barra de desplazamiento al hacer hover */
}
.message-container-light::-webkit-scrollbar-track {
  background: transparent; /* Fondo transparente */
}
.message-container-telegram::-webkit-scrollbar {
  width: 8px; /* Ancho de la barra de desplazamiento */
}
.message-container-telegram::-webkit-scrollbar-thumb {
  background-color: rgba(99, 99, 99, 0.2); /* Color de la barra de desplazamiento */
  border-radius: 10px; /* Borde redondeado de la barra de desplazamiento */
}
.message-container-telegram::-webkit-scrollbar-thumb:hover {
  background-color: rgba(85, 85, 85, 0.4); /* Cambia el color de la barra de desplazamiento al hacer hover */
}
.message-container-telegram::-webkit-scrollbar-track {
  background: transparent; /* Fondo transparente */
}
.input-container, .input-container-light {  
  display: flex;  
  align-items: center; /* Alinea los elementos verticalmente */  
  background-color: #1B2831; /* Fondo oscuro */  
  padding: 5px;  
  position: relative; /* Esto permite que el menú se posicione correctamente respecto al input */
}

.input-container-light {  
  background-color: #EBE5DE;  
}  

.input-field {  
  flex-grow: 1;  
  border: none;  
  font-size: 13px;  
  padding: 10px;  
  border-radius: 50px;  
  margin: 5px;  
  height: 65px; /* Altura fija inicial */
  max-height: auto;
  overflow-y: auto; 
  z-index: 1; 
}



/* Estilos para los botones de control */  
.control-button {  
  cursor: pointer;  
  font-size: 25px; /* Ajusta el tamaño del icono */  
  margin: 0 5px; /* Margen horizontal */  
}  

/* Estilos para el botón de enviar */  
.send-button {  
  background-color: #25d366; /* Color de fondo */  
  color: white; /* Color del texto */  
  border: none; /* Sin borde */  
  border-radius: 50%; /* Bordes redondeados */  
  width: 40px; /* Ancho fijo */  
  height: 40px; /* Altura fija */  
  display: flex;  
  align-items: center;  
  justify-content: center; /* Centrado del icono */  
  cursor: pointer;  
  transition: background-color 0.2s;  
  margin-left: 5px; /* Margen a la izquierda para separación */  
}  

.send-button:hover {  
  background-color: #128c63; /* Color al pasar el mouse */  
}  

/* Estilos para mensajes enviados */

.sent-message {
  background-color: #0e685f; /* Color de fondo de mensajes enviados */
  padding: 5px;
  font-size: 13px;
  margin: 5px 5px 0 auto;
  padding: 5px 4px 10px 5px;
  border-radius: 10px;
  color: white;
  display: block;
  flex-direction: column;
  position: relative;
  width: fit-content; /* Ajusta automáticamente el ancho al contenido */
  max-width: 70%; /* Ancho máximo para evitar que el texto sea demasiado ancho */
  min-width: 20%;
}

.sent-message::after {
  content: '';
  position: absolute;
  top: 0%;
  right: -3px; /* Ajusta la posición del triángulo */
  transform: translateY(-50%) rotate(40deg); /* Rotación del triángulo */
  border-width: 12px;
  border-style: solid;
  border-color: transparent #0e685f transparent transparent; /* Color del triángulo */
}
.sent-message-light {
  background-color: #d8f3bf; /* Color de fondo de mensajes enviados */
  padding: 5px;
  font-size: 13px;
  margin: 5px 5px 0 auto;
  padding: 5px 4px 10px 5px;
  border-radius: 10px;
  color:#000000;
  display: block;
  flex-direction: column;
  position: relative;
  width: fit-content; /* Ajusta automáticamente el ancho al contenido */
  max-width: 70%; /* Ancho máximo para evitar que el texto sea demasiado ancho */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); /* Agregar sombra */
  min-width: 20%;
}

.sent-message-light::after {
  content: '';
  position: absolute;
  top: 0%;
  right: -3px; /* Ajusta la posición del triángulo */
  transform: translateY(-50%) rotate(40deg); /* Rotación del triángulo */
  border-width: 12px;
  border-style: solid;
  border-color: transparent #d8f3bf transparent transparent; /* Color del triángulo */
}

.sent-message .checkIcon-message {
  font-size: 18px;
  position: absolute;
  bottom: 1px;
  right: 5px;
}
.sent-message-light .checkIcon-message {
  font-size: 18px;
  position: absolute;
  bottom: 1px;
  right: 5px;
}

.delivered-icon {
  color: #e0e0e0; 
}

.read-icon {
  color: rgb(67, 166, 247); 
}

.received-message {
  background-color: #314657; /* Color de fondo de mensajes recibidos */
  font-size: 13px;
  padding: 5px 10px 10px 5px; /* Ajustar el padding para acomodar mejor el contenido */
  margin: 5px 10px 0; /* Ajustar el margen */
  border-radius: 10px;
  color: white;
  display: block;
  flex-direction: column;
  position: relative;
  width: fit-content; /* Ajusta automáticamente el ancho al contenido */
  max-width: 70%; /* Ancho máximo para evitar que el texto sea demasiado ancho */
  min-width: 20%;
  
}

.received-message::after {
  content: '';
  position: absolute;
  top: 0%;
  left: -3px; /* Ajusta la posición del triángulo */
  transform: translateY(-50%) rotate(-40deg); /* Rotación del triángulo */
  border-width: 12px;
  border-style: solid;
  border-color: transparent transparent transparent #314657; /* Color del triángulo */
}
.received-message-light {
  background-color: #FFFFFF; /* Color de fondo de mensajes recibidos */
  font-size: 13px;
  padding: 5px 10px 10px 5px; /* Ajustar el padding para acomodar mejor el contenido */
  margin: 5px 10px 0; /* Ajustar el margen */
  border-radius: 10px;
  color:#000000;
  display: block;
  flex-direction: column;
  position: relative;
  width: fit-content; /* Ajusta automáticamente el ancho al contenido */
  max-width: 70%; /* Ancho máximo para evitar que el texto sea demasiado ancho */
  min-width: 20%;

  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); /* Agregar sombra */
}


.received-message-light::after {
  content: '';
  position: absolute;
  top: 0%;
  left: -3px; /* Ajusta la posición del triángulo */
  transform: translateY(-50%) rotate(-40deg); /* Rotación del triángulo */
  border-width: 12px;
  border-style: solid;
  border-color: transparent transparent transparent #FFFFFF; /* Color del triángulo */

}

.message-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-right: 5px;
  margin-left: 5px;
  white-space: pre-wrap;
}

.message-time {
  font-size: 10px; /* Puedes ajustar el tamaño de fuente según tus preferencias */
  color: rgb(216, 216, 216); /* Puedes ajustar el color según tus preferencias */
  position: absolute;
  right: 25px;
  bottom: 0;
}

.message-time-light {
  font-size: 10px; 
  color: rgb(65, 64, 64);
  position: absolute;
  margin-left: 5px;
  bottom: 0;
  margin-top: 5px;
}
.parent-element {
  display: flex;
  justify-content: center;
  align-items: center;
 }
 
 .date-message {
  background-color: #314657;
  font-size: 12px;
  padding: 6px;
  border-radius: 5px;
  color: white;
  display: inline;
  text-align: center;
 }

 .date-message-light {
  background-color: #e9f8ff;
  font-size: 12px;
  padding: 6px;
  border-radius: 5px;
  color: rgb(0, 0, 0);
  display: inline;
  text-align: center;
 }
 
 .custom-label {
  display: block;
  position: absolute;
  top: 20%;
  right: 10;
  left: 0;
  width: 46px;
  height: 26px;
  margin: 0 auto;
  background-color: rgba(191, 255, 234, 0.733);
  border: 2px solid rgb(75, 114, 94);
  border-radius: 56px;
  transform: translateY(-50%);
  cursor: pointer;
  transition: 0.3s ease background-color;
  overflow: hidden;
  user-select: none;
}

.star {
  position: absolute;
  top: -3px;
  left: 10px;
  width: 15px;
  height: 15px;
  font-size: 18px;
  transform: scale(1);
  border-radius: 50%;
  transition: 0.3s ease top, 0.3s ease left, 0.3s ease transform, 0.3s ease background-color;
  z-index: 1;
  background-color: none;
}

.moon {
  position: absolute;
  bottom: -20px;
  right: 15px;
  width: 15px;
  height: 15px;
  font-size: 15px;
  transition: 0.3s ease bottom;
}

.moon:before {
  content: "";
  position: absolute;
  top: -12px;
  left: -17px;
  background-color: none;
  width: 15px;
  height: 15px;
  font-size: 15px;
  transition: 0.2s ease background-color;
}

#toggle_checkbox:checked + .custom-label {
  background-color: rgb(75, 114, 94);
  border: 2px solid rgb(7, 189, 128);
}

#toggle_checkbox:checked + .custom-label .star {
  top: 2px;
  left: 64px;
  transform: scale(0.6);
}

#toggle_checkbox:checked + .custom-label .moon {
  bottom: 7px;
}
#toggle_checkbox {
  position: absolute;
  left: -9999px;
}

.agent-name-light{
  font-size: 12px;
    color: green;
    margin-left: 4px;
}
.agent-name{
  font-size: 12px;
    color: rgb(89, 207, 89);
    margin-left: 4px;
}

.container-side-component {
  overflow-y: auto;
  overflow-x: hidden;
}
.container-side-component::-webkit-scrollbar {
  width: 8px; /* Ancho de la barra de desplazamiento */
}

.container-side-component::-webkit-scrollbar-thumb {
  background-color: rgba(128, 128, 128, 0.2); /* Color de la barra de desplazamiento */
  border-radius: 10px; /* Borde redondeado de la barra de desplazamiento */
}

.container-side-component::-webkit-scrollbar-thumb:hover {
  background-color: rgba(85, 85, 85, 0.4); /* Cambia el color de la barra de desplazamiento al hacer hover */
}

.container-side-component::-webkit-scrollbar-track {
  background: transparent; /* Fondo transparente */
}

.card-conversacion {
  border: none; /* Eliminamos el borde */
  border-radius: 8px;
  margin-top: 5px;
  min-height: 50px;
  margin: 0;
  padding: 0;
  cursor: pointer;
  overflow: hidden;
  transition: background-color 0.3s ease;
  width: 100%; /* Ocupa el ancho total */
  position: relative; /* Asegura que la posición absoluta sea relativa a este contenedor */

}

.card-conversacion:hover {
  background-color: #dfdede;
}
.card-conversacion-unreading:hover {
  background-color: #dfdede;
}
.chat-item {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e7e6e6; /* Línea que separa los chats */
  padding: 10px;
}

.chat-item__img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 5px;
}

.chat-item__info {
  flex-direction: column;
}
.chat-item__badge {
  font-size: 16px;
  margin-left: auto; /* Empuja el elemento completamente hacia la derecha */
  margin-right: 10px;
  align-self: flex-end; /* Alinea el elemento en la esquina superior derecha */
  position: absolute; /* Permite usar coordenadas absolutas */
  top: 50; /* Coloca el elemento en la parte superior */
  right: 0; /* Coloca el elemento en la parte derecha */
}
.chat-item__date {
  font-size: 12px;
  color: #888;
  margin-left: auto; /* Empuja el elemento completamente hacia la derecha */
  align-self: flex-end; /* Alinea el elemento en la esquina superior derecha */
  position: absolute; /* Permite usar coordenadas absolutas */
  top: 0; /* Coloca el elemento en la parte superior */
  right: 0; /* Coloca el elemento en la parte derecha */
}
.chat-item__name {
  font-weight: bold;

}

.chat-item__description {
  font-size: 13px;
  color: #7a7a7a; /* Color de texto para la descripción */
  max-width: 95%; /* Establece el ancho máximo del contenedor */
  overflow: hidden; /* Oculta cualquier contenido que exceda el ancho */
  text-overflow: ellipsis; /* Muestra puntos suspensivos (...) cuando el texto se trunca */
  white-space: nowrap;

}

.window-close {
  margin-left: auto; /* Empuja el elemento completamente hacia la derecha */
  align-self: flex-end; /* Alinea el elemento en la esquina superior derecha */
  position: absolute; /* Permite usar coordenadas absolutas */
  top: 0; /* Coloca el elemento en la parte superior */
  right: 0; /* Coloca el elemento en la parte derecha */
  padding-right : 8px;
  cursor: pointer;
  transition: transform 0.1s ease;
}

.window-close:hover {
  transform: scale(1.2);
}


.add-note-button {
  transform: rotate(90deg);
  cursor: pointer;
  transition: transform 0.5s ease;
  font-size: 25px;
  margin: 2px;
}
.add-note-button:hover {
  transform: scale(1.1);
}
.custom-dropdown .dropdown-menu {
  margin-top: -190px; /* Ajusta este valor según sea necesario */
  margin-left: -75px;
}
 
.floating-buttons {
  position: absolute;
  top: 70px; /* Ajusta la distancia desde la parte superior */
  right: 20px; /* Ajusta la distancia desde el lado derecho */
  z-index: 999;
}
.floating-button {
  font-size: 20px;
  cursor: pointer;
  transition: transform 0.4s ease;
}

.floating-button:hover {
  transform: scale(1.2);
}

.tooltip-container {
  --background-light: #c7b39d;
  --background-dark: #ffa32be7; 
  --text-color-light: #ffffff;
  --text-color-dark: #ffffff;
  --bubble-size: 12px;
  --glow-color: rgba(255, 255, 255, 0.5); /* Brighter glow color */

  background: var(--background-light);
  cursor: pointer;
  transition: all 0.2s;
  font-size: 20px;
  padding: 0em 0.4em 0.3em 0.4em;
  color: var(--text-color-light);
  border-radius: 20px; /* Rounded edges */
  display: inline-block;
  position: relative;
}
/* Style for the bubble tooltip */
.tooltip {
  position: absolute;
  bottom: calc(100% + 5px); /* Mueve el tooltip arriba del botón y agrega un poco de espacio */
  left: 50%;
  transform: translateX(-50%);
  padding: 0.6em 1em;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: all 0.3s;
  border-radius: var(--bubble-size);
  background: #ffa32bbd ;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Triangle arrow for the bubble tooltip */
.tooltip::before {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%);
  border-style: solid;
  border-width: 8px 8px 0;
  border-color: #ffa32bbd  transparent transparent;
}

/* Tooltip appearance on hover */
.tooltip-container:hover {
  background: var(--background-dark);
  color: var(--text-color-dark);
  box-shadow: 0 0 20px var(--glow-color); /* Brighter glow effect */
}

.tooltip-container:hover .tooltip {
  top: -120%; /* Adjusted position for the bubble tooltip */
  opacity: 1;
  bottom: calc(100% + 5px); /* Mueve el tooltip arriba del botón y agrega un poco de espacio */
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.selected-file-container {
  bottom: 0px; /* Ajusta la distancia desde la parte inferior según tu diseño */
  width: 100%;
}

.selected-file-card {
  background-color: #eeeeeed3;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.selected-file-card p {
  margin: 0;
}
.max-size-img {
  width: 430px; 
  height: 350px; 
  object-fit: cover; 
  cursor: pointer;
}
.max-size-img-modal {
  max-width: max-content;
  max-height: 550px;
}

.selected-conversation-card {
  background-color: rgba(0, 0, 0, 0.1); /* Color de fondo ligeramente más oscuro */
}

.audio-player {
  min-width: 280px;
  height: 40px; /* Ajusta la altura según tus necesidades */
  border-radius: 10px;
  background-color: #e2e2e2da; /* Color de fondo */
  outline: none;
  margin-bottom: 5px;
}

.audio-player::-webkit-media-controls-panel {
  background-color: #e2e2e2da; /* Color de fondo de los controles */
}

.audio-player::-webkit-media-controls-play-button,
.audio-player::-webkit-media-controls-timeline,
.audio-player::-webkit-media-controls-current-time-display,
.audio-player::-webkit-media-controls-time-remaining-display,
.audio-player::-webkit-media-controls-volume-slider {
  filter: brightness(1) invert(-1); /* Cambia el color de los iconos */
}

.shortcut-menu {
  position: absolute; /* Posiciona el menú dentro del contenedor del input */
  bottom: 70%; /* Coloca el menú justo encima del input */
  left: 0; /* Alinea el menú con el input */
  width: calc(40% - 20px); /* Asegura que el menú tenga el mismo ancho que el input, con margen */
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Añade una sombra para un efecto flotante */
  max-height: 150px;
  overflow-y: auto;
  list-style-type: none;
  padding: 5px;
  z-index: 2; /* Asegúrate de que el menú esté por encima del input */
}

.shortcut-menu li {
  padding: 8px;
  font-size: 12px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.shortcut-menu li:hover, .shortcut-menu li.highlighted {
  background-color: #eaeaea;
  font-weight: bold;
}
.dropzone {
  width: 100%;
  border: 2px dashed gray;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
}

.dropzone.active {
  border-color: green;
  background-color: #e0f7fa;
}

.blink {
  animation: blink-animation 1s steps(5, start) infinite;
}

@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
.floating-button-sc {
  position: absolute;
  bottom: 20px;
  right: 20px;
  background-color: #1667c4; /* Color de fondo */
  color: white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border: none;
  cursor: pointer;
  z-index: 1000; /* Para asegurarse de que quede por encima de otros elementos */
}

.floating-button-sc:hover {
  background-color: #08355a; /* Color de fondo al pasar el mouse */
}
