.floating-button-PhonePanel {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #4a90e2; /* Azul más suave */
  color: white;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.floating-button-PhonePanel:hover {
  background-color: #357ABD; /* Azul más oscuro */
  transform: scale(1.1);
}

.phone-panel {
  position: fixed;
  bottom: 80px;
  right: 20px;
  width: 280px;
  background-color: #ffffff;

  border-radius: 16px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
  padding: 30px;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #555; /* Gris oscuro */
}

.contact-name {
  font-size: 18px;
  color: #333333; /* Gris más oscuro */
  font-weight: 600;
  text-align: center;
  margin-bottom: 6px;
}

.registration-status {
  text-align: center;
  font-size: 14px;
  color: #2deb27; /* Gris suave */
  margin-bottom: 10px;
}

.dial-display {
  background-color: #f8f9fa; /* Fondo claro */
  border: 1px solid #ddd;
  border-radius: 12px;
  padding: 12px;
  text-align: center;
  font-size: 20px;
  margin-bottom: 15px;
  width: 100%;
  box-sizing: border-box;
  color: #666666;
}

.dial-pad {
  display: grid;
  padding: 10px;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
}

.dial-button {
  background-color: #ffffff;
  border: 2px solid #0044913a;
  border-radius: 50%;
  padding: 10px;
  font-size: 20px;
  color: #4a90e2;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;
}

.dial-button:hover {
  background-color: #6b4ae27e;
  color: #ffffff;
  transform: scale(1.05);
}

.call-actions {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.call-button, .end-call-button, .transfer-button {
  background-color: #4a90e2;
  color: white;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.call-button:hover, .end-call-button:hover, .transfer-button:hover {
  background-color: #357ABD;
  transform: scale(1.1);
}

.call-timer {
  margin-top: 20px;
  text-align: center;
  font-size: 16px;
  color: #555555; /* Gris oscuro */
}

.call-indicator {
  text-align: center;
  color: red;
  font-weight: bold;
  animation: blink 1s infinite;
}

@keyframes blink {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}
