
.wschat-container {
    display: flex;
    flex-direction: column;
    background-color: #f0f0f0;
    height: 80vh;
    width: 50vh;
    justify-content: space-between;
  }
  
.wschat-header {
  background-color: #498ca7; /* Cambiar el color de fondo */
  color: white;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.avatar {
  width: 40px; /* Tamaño del contenedor del avatar */
  height: 40px;
  overflow: hidden;
  border-radius: 50%; /* Forma circular */
  margin-right: 10px; /* Espacio entre el avatar y el nombre */
  padding: 10;

}

.avatar img {
  width: 100%; /* Hace que la imagen del avatar ocupe todo el contenedor */
  height: 100%;
  object-fit: cover; /* Recorta la imagen para ajustar al contenedor */

}


/* Estilos para un aspecto más moderno en el encabezado */
.wschat-header h1 {
  margin: 0;
  font-size: 24px;
}

.wschat-header span {
  font-size: 14px;
  opacity: 0.7;
}
  /* Estilos para el nombre del contacto */
  .wscontact-name {
    font-size: 20px;
    font-weight: 600;
    margin: 0;
    margin-left: 5px ;
  }
  
  /* Estilos para el estado del contacto */
  .wscontact-status {
    font-size: 14px;
    margin: 0;
    margin-left: 10px ;
  }
  
  .wsmessage-container {
    background-color: #f7f7f7; /* Cambiar el color de fondo */
    flex-grow: 1;
    padding: 15px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
  }
  
  /* Estilos para la barra de desplazamiento en navegadores WebKit (Safari y Chrome) */
  .wsmessage-container::-webkit-scrollbar {
    width: 8px; /* Ancho de la barra de desplazamiento */
  }
  
  .wsmessage-container::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.2); /* Color de la barra de desplazamiento */
    border-radius: 10px; /* Borde redondeado de la barra de desplazamiento */
  }
  
  .wsmessage-container::-webkit-scrollbar-thumb:hover {
    background-color: rgba(85, 85, 85, 0.4); /* Cambia el color de la barra de desplazamiento al hacer hover */
  }
  
  .wsmessage-container::-webkit-scrollbar-track {
    background: transparent; /* Fondo transparente */
  }
  
  
  /* Estilos para el contenedor del campo de entrada y botón de enviar */
  .wsinput-container {
    display: flex;
    align-items: center;
    background-color: #66686911;
    padding: 5px;
  }
  
  /* Estilos para el campo de entrada de mensajes */
  .wsinput-field {
    flex-grow: 1;
    border:#ffffff;
    font-size: 13px;
    padding: 10px;
    border-radius: 50px;
    margin-right : 8px;
  
  }
/* Estilos para mensajes enviados */
.wssent-message {
  background-color: #4caf50; /* Cambiar el color de fondo */
  padding: 10px;
  font-size: 14px; /* Aumentar el tamaño del texto */
  margin: 10px 0;
  border-radius: 25px 30px 0px 25px ; /* Aumentar el radio de borde */
  color: white;
  max-width: 70%;
  float: right;
  clear: both;
  position: relative;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Agregar sombra */
}

/* Estilos para mensajes recibidos */
.wsreceived-message {
  background-color: #ff9800; /* Cambiar el color de fondo */
  padding: 10px;
  font-size: 14px; /* Aumentar el tamaño del texto */
  margin: 10px 0;
  border-radius:  0px 25px 30px 25px;
  color: white;
  max-width: 70%;
  float: left;
  clear: both;
  position: relative;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Agregar sombra */
}
