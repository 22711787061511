.signatureCanvas {
    border: 1px solid black;
    width: 100%;
    height: 500px;
    margin-top: 75px;
}

.modal {
    font-size: 12px;
  }
  .modal > .header {
    width: 100%;
    border-bottom: 1px solid gray;
    font-size: 18px;
    text-align: center;
    padding: 5px;
  }
  .modal > .content {
    width: 100%;
    padding: 10px 5px;
  }
  .modal > .actions {
    width: 100%;
    padding: 10px 5px;
    margin: auto;
    text-align: center;
  }
  .modal > .close {
    cursor: pointer;
    position: absolute;
    display: block;
    padding: 2px 5px;
    line-height: 20px;
    right: -10px;
    top: -10px;
    font-size: 24px;
    background: #ffffff;
    border-radius: 18px;
    border: 1px solid #cfcece;
  }

  .example-warper{
    border: 2px dashed;
    display: flex;
    flex-wrap: wrap;
    margin: 10px;
    padding: 20px 5%;
    white-space: pre-line;
    justify-content: space-around;
  }

.button-firma {
    background-color: #0f0f0f;
    border: 3px dashed #193f7e;
    cursor: pointer;
    display: inline-block;
    font-family: Roboto, Arial, sans-serif;
    font-size: 14px;
    letter-spacing: 1.px;
    text-transform: uppercase;
    transition: .3s;
    color: #ffffff;
    margin: 20px !important;
    padding: 0 30px;
    position: relative;
  width: 50% !important;

}

.button {
    background-color: #0f0f0f;
    border: 3px dashed #193f7e;
    cursor: pointer;
    display: inline-block;
    font-family: Roboto, Arial, sans-serif;
    font-size: 14px;
    letter-spacing: 1.px;
    text-transform: uppercase;
    transition: .3s;
    color: #ffffff;
    margin: 15px 15px;
    padding: 0 30px;
    position: relative;


}

.button.hover{
    border-color: #2980b9;
    color: #000!important;
}

.button-guardar {
  background-color: #2980b9;
  border: 3px dashed #193f7e;
  cursor: pointer;
  display: inline-block;
  font-family: Roboto, Arial, sans-serif;
  font-size: 14px;
  letter-spacing: 1.px;
  text-transform: uppercase;
  transition: .3s;
  color: #ffffff;
  margin: 20px !important;
  padding: 0 30px;
  position: relative;
width: 40% !important;
height: 40px;

}
.button-cerrar {
  background-color: #2980b9;
  border: 3px dashed #193f7e;
  cursor: pointer;
  display: flex;
  font-family: Roboto, Arial, sans-serif;
  font-size: 14px;
  letter-spacing: 1.px;
  text-transform: uppercase;
  transition: .3s;
  color: #ffffff;
  margin: 20px !important;
  padding: 0 30px;
  position: relative;
  width: 6% !important;
  height: 40px;
  text-align: center;
  justify-content: center;
  align-items: center;

}


@media only screen and (max-width: 480px) {
  .signatureCanvas {
    margin-top: 25px;
    height: 150px;
    /* width: 250px; */
  }
  .modal > .header {
    font-size: 14px;
  }

  .button {
    font-size: 10px;
    margin: 5px;
    padding: 0 10px;
  }
  .pop-content {
    height: 450px;
  }
}

@media only screen and (max-width: 768px) {
  .signatureCanvas {
    margin-top: 50px;
    height: 400px;
  }
  .popup-content {
    width: 90%;
    height: 90%;
  }
  .modal > .header {
   -size: 16px;
  }
  .modal > .content {
    padding: 5px;
  }
  .modal > .actions {
    padding: 5px;
  }
  .button {
    font-size: 12;
    margin: 5px 10px;
    padding: 0 20px;
  }
}

.row-firma{
  border: 1px dashed ;
}

.carousel-back{
  background: black !important;
}