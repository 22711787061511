
.ws-management-header {
    background-color: rgba(23, 89, 94, 0.575);
    color: white;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .ws-gestion-card{
    margin-top: 0;
    max-width: 100%;
    margin-left: 10px;
    cursor: pointer;
  }
  .management-body-container {
    max-height: 150px;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: thin; /* Oculta la barra de desplazamiento en Firefox */
    scrollbar-color: transparent transparent; /* Cambia el color de la barra de desplazamiento en Firefox */
  }
  .ws-management-body-container::-webkit-scrollbar {
    width: 8px; /* Ancho de la barra de desplazamiento */
  }
  
  .ws-management-body-container::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.2); /* Color de la barra de desplazamiento */
    border-radius: 10px; /* Borde redondeado de la barra de desplazamiento */
  }
  
  .ws-management-body-container::-webkit-scrollbar-thumb:hover {
    background-color: rgba(85, 85, 85, 0.4); /* Cambia el color de la barra de desplazamiento al hacer hover */
  }
  
  .ws-management-body-container::-webkit-scrollbar-track {
    background: transparent; /* Fondo transparente */
  }
  .ws-gestion-card.active {
    border: 2px solid rgb(10, 185, 10); /* Borde verde para tarjetas activas */
  }

  .ws-avatar-container {
    display: flex;
    align-items: center;
  }
  
  .ws-avatar {
    width: 30px;
    height: 30px; 
    border-radius: 50%;
    margin-left: 10px;
  }
  .ws-management-contain {
    margin-top: 5px;
    justify-content: start;

  }

  .search-input {
    padding: 4px;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-bottom: 15px;
    font-size: 16px;
    width: 90%;
    box-sizing: border-box;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
